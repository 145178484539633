import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"


const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const headerNews = data.headerNews.edges
  const posts = data.postNews.edges
  const postsBlog = data.postBlog.edges

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="ホーム" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      {/* IE非対応メッセージ */}
      <Helmet>
        <script type="application/javascript">{`
            {
              var userAgent = window.navigator.userAgent.toLowerCase()
              if(userAgent.indexOf("msie") != -1 || userAgent.indexOf('trident') != -1) {
                alert("Internet Explorerはサポート対象外のため、正しく表示されません。Chrome、Edge、Safari、Firefoxをお使いください。")
              }
            }
        `}</script>
      </Helmet>
      <Seo title="ホーム" />
      <div className="header-news">
        <h2>最新ニュースリリース</h2>
        {headerNews.map(post => {
          const title = post.node.title || post.node.slug

          return (
            <>
              <p>
                {post.node.publishDate}
                <span>お知らせ</span>
                <br className="mobile-br" />
                <Link to={`/${post.node.category}/${post.node.slug}`}>
                  {title}
                </Link>
              </p>
            </>
          )
        })}
      </div>
      <div className="index-headline">
        <div className="index-headline-left">
          <StaticImage
            layout="constrained"
            src="../images/headline.jpg"
            max-width={430}
          />
        </div>
        <div className="index-headline-right">
          <span>About</span>
          <hr />
          <h3>
            最新技術による高品質な映像伝送の実現
            <br />
            省配線・環境配慮型設計をご提案
          </h3>
          <div className="index-headline-right-flex">
            <StaticImage
              layout="constrained"
              src="../images/icon-arrow-before.svg"
              height={20}
            />
            <div className="index-headline-right-item">
              <p>
                <Link to={`/promotion/transfer`}>画像伝送装置</Link>
              </p>
            </div>
            <div className="index-headline-right-item">
              <p>
                最新のSRT映像伝送技術で高品質な映像
                を伝送。映像だけでなく制御信号にも対応 工場のIoT化に貢献
              </p>
            </div>
          </div>
          <div className="index-headline-right-flex">
            <StaticImage
              layout="constrained"
              src="../images/icon-arrow-before.svg"
              height={20}
            />
            <div className="index-headline-right-item">
              <p>
                <Link to={`/promotion/vwvds`}>
                  電源重畳ユニット
                  <br />
                  (ワンケーブルユニット)
                </Link>
              </p>
            </div>
            <div className="index-headline-right-item">
              <p>
                １本のケーブルで２台のカメラ映像伝送可
                同軸ケーブルでIPカメラ映像伝送も
                多様なラインナップで各種ニーズに対応
              </p>
            </div>
          </div>
          <div className="index-headline-right-flex">
            <StaticImage
              layout="constrained"
              src="../images/icon-arrow-before.svg"
              height={20}
            />
            <div className="index-headline-right-item">
              <p>
                <Link to={`https://isec-promotion.github.io/face_and_thermo/`}>
                  非接触型体表面温度測定器
                  <br />
                  (フェイス＆サーモ)
                </Link>
              </p>
            </div>

            <div className="index-headline-right-item">
              <p>
                コロナ禍に最適な非接触型温度測定器
                データ記憶、Excelファイルで出力お可能
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="index-product">
        <span>Product</span>
        <hr />
        <h1>製品一覧</h1>
        <div className="list">
          <Link to="/category/camera">
            <div className="entry">
              <h2>監視カメラ</h2>
              <StaticImage
                layout="constrained"
                src="../images/product_camera.png"
                max-width={300}
              />
            </div>
          </Link>
          <Link to="/category/recorder">
            <div className="entry">
              <h2>レコーダー</h2>
              <StaticImage
                layout="constrained"
                src="../images/product_recorder.png"
                max-width={300}
              />
            </div>
          </Link>
          <Link to="/category/transfer">
            <div className="entry">
              <h2>画像伝送装置</h2>
              <StaticImage
                layout="constrained"
                src="../images/product_transfer.png"
                max-width={300}
              />
            </div>
          </Link>
          <Link to="/category/thermo">
            <div className="entry">
              <h2>非接触型測温計</h2>
              <StaticImage
                layout="constrained"
                src="../images/product_thermo.png"
                max-width={300}
              />
            </div>
          </Link>
          <Link to="/category/miscs">
            <div className="entry">
              <h2>周辺機器</h2>
              <StaticImage
                layout="constrained"
                src="../images/product_miscs.png"
                max-width={300}
              />
            </div>
          </Link>
          <Link to="/category/software">
            <div className="entry">
              <h2>ソフトウェア</h2>
              <StaticImage
                layout="constrained"
                src="../images/product_software.png"
                max-width={300}
              />
            </div>
          </Link>
        </div>
      </div>
      <div className="index-news">
        <span>News Release</span>
        <hr />
        <div className="index-news-flex">
          <div className="index-news-flex-item">
            <h1>お知らせ</h1>
          </div>
          <div className="index-news-flex-item">
            <Link to="/category/news">お知らせ一覧へ</Link>
          </div>
        </div>
        <ol style={{ listStyle: `none` }}>
          {posts.map(post => {
            const title = post.node.title || post.node.slug

            return (
              <li key={`/category/${post.node.category}/${post.node.slug}`}>
                <article
                  className="post-list-item-news"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2>
                      <span style={{ color: `black`, fontWeight: "normal", marginRight: "5px" }}>
                        {post.node.publishDate}
                      </span>
                      <Link
                        to={`/${post.node.category}/${post.node.slug}`}
                        itemProp="url"
                      >
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                    <small></small>
                  </header>
                  <section></section>
                </article>
              </li>
            )
          })}
        </ol>
      </div>
      <div className="index-tech">
        <span>Tech Blog</span>
        <hr />
        <div className="index-news-flex index-tech-margin">
          <div className="index-news-flex-item">
            <h1>技術ブログ</h1>
          </div>
          <div className="index-news-flex-item">
            <Link to="/category/blog">技術ブログ一覧へ</Link>
          </div>
        </div>
        <div className="post-list">
          {postsBlog.map(post => {
            const title = post.node.title || post.node.slug
            const image = post.node.image

            return (
              <article
                className="post-list-item index-tech-post"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <Link
                    to={`/${post.node.category}/${post.node.slug}`}
                    itemProp="url"
                  >
                    <picture>
                      {/* サムネ画像なし */}
                      {!image && (
                        <>
                          <source type="image/webp" srcSet="../images/no-image-thumbnail.webp" />
                          <img src="../images/no-image-thumbnail.png" alt="post-cover" />
                        </>
                      )}
                      {image && (
                        <>
                          <source
                            type="image/webp"
                            srcSet={image.gatsbyImageData.images.sources[0].srcSet}
                          />
                          <img src={image.file.url} alt="post-cover"></img>
                        </>
                      )}
                    </picture>
                    <div className="index-tech-item">
                      <p>
                        <small>{post.node.publishDate}</small>
                      </p>
                      <h2>
                        <span itemProp="headline">{title}</span>
                      </h2>
                    </div>
                  </Link>
                  {post.node.postTags &&
                    post.node.postTags.map(tag => <li>{tag}</li>)}
                </header>
              </article>
            )
          })}
        </div>
      </div>
      <div className="index-portcheck">
        <div className="index-portcheck-item">
          <p>CHECK</p>
          <StaticImage layout="constrained" src="../images/white-arrow.svg" />
          <h1>ポート開放確認</h1>
        </div>
        <div className="portcheck">
          <Link to="/tools">ポート開放確認</Link>
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    headerNews: allContentfulPost(
      limit: 1
      filter: { category: { eq: "news" } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          title
          slug
          category
          publishDate(locale: "ja-JP", formatString: "YYYY/MM/DD")
        }
      }
    }
    postNews: allContentfulPost(
      limit: 6
      filter: { category: { eq: "news" } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          title
          image {
            gatsbyImageData( quality: 100, formats: WEBP)
            file {
              url
            }
          }
          updatedAt(locale: "ja-JP", formatString: "YYYY/MM/DD")
          description {
            description
          }
          slug
          category
          publishDate(locale: "ja-JP", formatString: "YYYY/MM/DD")
          postTags
        }
      }
    }
    postBlog: allContentfulPost(
      limit: 6
      filter: { category: { eq: "blog" } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          title
          image {
            gatsbyImageData( quality: 100, formats: WEBP)
            file {
              url
            }
          }
          updatedAt(locale: "ja-JP", formatString: "YYYY年MM月DD日")
          description {
            description
          }
          slug
          category
          publishDate(locale: "ja-JP", formatString: "YYYY/MM/DD")
          postTags
        }
      }
    }
  }
`
